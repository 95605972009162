import { Router } from "@angular/router";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NB_WINDOW,
} from "@nebular/theme";

import { filter, map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { environment } from "@environments/environment";
import { GlobalService } from "@services/global.service";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  /*public user_name: string;
  public user_email: string;*/

  static avaiableThemes = [
    {
      value: "default",
      name: "Padrão",
    },
    {
      value: "dark",
      name: "Escuro",
    },
    {
      value: "cosmic",
      name: "Cosmic",
    },
    {
      value: "corporate",
      name: "Corporate",
    },
  ];

  private _currentTheme = "default";

  get currentTheme() {
    return this._currentTheme;
  }

  set currentTheme(newTheme) {
    this._currentTheme = newTheme;

    // Salva o tema para casos de refresh
    localStorage.setItem('currentTheme', newTheme)

    // Atualiza o tema
    this.themeService.changeTheme(newTheme);
  }

  get themes() {
    return HeaderComponent.avaiableThemes;
  }

  userMenu = this.getMenuItems();

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    public globals: GlobalService,
    @Inject(NB_WINDOW) private window,
  ) {}

  getMenuItems(): Array<NbMenuItem> {
    return [
      // {
      //   title: "Tema",
      //   children: [
      //     {
      //       title: "Ligth",
      //       data: "default",
      //     },
      //     {
      //       title: "Dark",
      //       data: "dark",
      //     },
      //     {
      //       title: "Cosmic",
      //       data: "cosmic",
      //     },
      //     {
      //       title: "Corporate",
      //       data: "corporate",
      //     },
      //   ],
      // },
      { title: "Sair", data: "logout" },
    ];
  }

  ngOnInit() {
    this.currentTheme = localStorage.getItem('currentTheme') || this.themeService.currentTheme;

    this.userMenu = this.getMenuItems();

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    // this.themeService
    //   .onThemeChange()
    //   .pipe(
    //     map(({ name }) => name),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe((themeName) => (this.currentTheme = themeName));

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => {
          return tag === "headerUserMenu";
        })
      )
      .subscribe((item) => {
        // console.log(item);
      });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.currentTheme = themeName;
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(false, "menu-sidebar");

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }



  getIconStatus(notificacao) {
    return notificacao.type;
  }

  getNotificationIcon(type) {
    switch (type) {
      case "success":
        return "checkmark-circle-outline";
      case "danger":
        return "close-circle-outline";
      case "info":
        return "alert-circle-outline";
      case "warning":
        return "alert-triangle-outline";
    }
  }

  get logoPath() {
    let theme = 'light'

    if (['dark', 'cosmic'].includes(this.currentTheme)) {
      theme = 'dark'
    }

    return `assets/images/${environment.company}-${theme}-theme.png`
  }

  clearNotifications() {

  }
}
