<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    
    <img class="logo" [src]="logoPath" (click)="navigateHome()" style="border: none;height: 43px;margin-left: -3px;;" >
  </div>

 
  <div class="form-control-group">

    <span *ngIf="globals.account != null && globals.account.username != null" class="user" [title]="globals.account.username">{{ globals.account.name }}</span>

    <nb-select style="margin-top: 4px"
      [selected]="currentTheme"
      (selectedChange)="changeTheme($event)"
      status="primary"
      size="small"
    >
      <nb-option *ngFor="let theme of themes" [value]="theme.value">
        {{ theme.name }}</nb-option>
    </nb-select>
  </div>

</div>